.waves {
    position:relative;
    width: 100%;
    height:15vh;
    margin-bottom:-7px; /*Fix for safari gap*/
    min-height:100px;
    max-height:150px;
  }

  .header {
    position:relative;
    /* background: linear-gradient(60deg, rgba(84,58,183,1) 0%, rgba(0,172,193,1) 100%); */
    color:white;
    background-color: #726cf8;
    background-image: linear-gradient(315deg, #726cf8 0%, #e975a8 74%);

    
    
  }

.avatar-class {
  width: 20rem;
  height: 20rem;
}

.circle {
  border-radius: 20rem;
}
  
  h2{
    padding: 0rem 4rem 4rem 4rem;
    font-kerning: auto;
    letter-spacing: 2.8rem;
    animation: fadein 3s linear forwards 1s;
    opacity: 0;
  }

  h1{
    text-align: start;
    padding: 4rem 4rem 0rem 4rem;
    font-size: 6rem;
    animation: fadein 3s linear forwards;
    opacity: 0;
    font-weight: 300;
  }

  .inner-header {
    height:25vh;
    width:100%;
    margin: 0;
    padding: 0;
  }

  #gentle-wave {
      filter: blur(0.25px)
  }

  .parallax > use {
    animation: move-forever 25s cubic-bezier(.55,.5,.45,.5) infinite;
  }
  .parallax > *:nth-child(1) {
    animation-delay: -2s;
    animation-duration: 7s;
  }
  .parallax > *:nth-child(2) {
    animation-delay: -3s;
    animation-duration: 10s;
  }
  .parallax > *:nth-child(3) {
    animation-delay: -4s;
    animation-duration: 13s;
  }
  .parallax > *:nth-child(4) {
    animation-delay: -5s;
    animation-duration: 20s;
  }
  .parallax > *:nth-child(5) {
    animation-delay: -7s;
    animation-duration: 23s;
  }

  @keyframes move-forever {
    from {
     transform: translate3d(-90px,0,0);
    }
    to {
      transform: translate3d(85px,0,0);
    }
  }
  /*Shrinking for mobile*/
  @media (max-width: 768px) {
    .waves {
      height:40px;
      min-height:40px;
    }
    .content {
      height:30vh;
    }
    h1 {
      font-size:38px;
    }

    h2 {
        font-size: 18px;
        letter-spacing: 1.2rem;
    }
    .avatar-class {
        width: 15rem;
        height: 15rem;
      }
      .circle {
        border-radius: 15rem;
      }

      .overlay{
          padding-top: 7.5rem !important;
      }
  }

  @keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
}